///////////////// HOOKS //////////////
import React, { useEffect, useState } from "react";
import { Accordion, Button, Modal, Form, Row, Col, Dropdown, FloatingLabel } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
///////////////// STORE //////////////
// import { getCliente } from '../../store/Clientes';
// import { getDispProd, getDispEst } from '../../store/Disponibilidade';
import { getCarrinho, putCarrinho, deleteCarrinho } from '../../../store/Carrinho';
import { postPedido, putPedido } from '../../../store/Pedidos';
import { useNavigate } from "react-router-dom";
///////////////// COMPONENTS //////////////
import { MdModeEditOutline } from "react-icons/md";
import { FaRegTrashCan, FaUpLong } from "react-icons/fa6";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import { NumericFormat } from 'react-number-format';
import LoadingIcon from '../../../components/loading/Loading';
import {convertDecimal} from '../../../function/maskInputMoney';
import api from "../../../services/api";

import { IoRefresh, IoCartOutline, IoClose } from "react-icons/io5";
const empresa = process.env.REACT_APP_NAME;



const ViewCarrinho = (props) => {
    const { selectCli } = useSelector(state => state.clienteReduce)
    const { dataCarrinho, isLoading } = useSelector(state => state.carrinhoReduce)
    const dispatch = useDispatch()
    const [refresh, setRefresh] = useState(true);
    const { select } = useSelector(state => state.filialReduce)
    const { dataTransp, selectTransportadora, selectRedespacho, selectTipoTransportadora } = useSelector(state => state.transpReduce)
    const { dataCondPag, selectCondPagamento } = useSelector(state => state.condPagReduce)
    const { dataTabPreco, selectTabelaPreco } = useSelector(state => state.precoReduce)
    const { dataUser, selectUser } = useSelector(state => state.userReduce)
    const [errorForm, setErrorForm] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [show, setShow] = useState(false);
    const [error, setError] = useState({ filial: '', cliente: '', carrinho: '', tipoTransportadora: '', transportadora: '', condPagamento: '', tabPreco: '' })
    const [toEmail, setToEmail] = useState();
    const [pedidoFinal, setPedidoFinal] = useState({});
    const [totalCarrrinho, setTotalCarrrinho] = useState(0);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [loadingSendPed, setLoadingSendPed] = useState(false);
    const [numCotacao, setNumCotacao] = useState({});
    const [erroMassage, setErroMassage] = useState({});

    const navigate = useNavigate();
    

    useEffect(() => {
        dispatch(getCarrinho())
        setError({})
        setToEmail(selectCli.emailCliente)
    }, [refresh, selectCli, props.show]);

    const validar = () => {
        const MENSAGENS_DE_ERRO = {
            filial: "Filial não selecionada",
            cliente: "Cliente não selecionado",
            transportadora: "Transportadora não selecionada",
            tipoTransportadora: "Tipo de frete não selecionado",
            condPagamento: "Condição de Pagamento não selecionada",
            tabPreco: "Tabela de preço não selecionada",
            carrinho: "Não existe produto no carrinho",
            qtd: "Quantidade indisponível",
            preco: "Preço inválido",
            desconto: "Desconto inválido",
            descontoPc: "Não podem ser concedidos descontos acima de 9%"
        };
        let numErrors = 0
        let errorMessage = {}

        if (!select.idFilial) {
            errorMessage.filial = MENSAGENS_DE_ERRO.filial;
            numErrors++
        }
        if (!selectCli.codCliente) {
            errorMessage.cliente = "Cliente não selecionado"
            numErrors++
        }
        if (!selectTransportadora.idErpTransp) {
            errorMessage.transportadora = "Transportadora não selecionada"
            numErrors++
        }
        if (!selectTipoTransportadora.value) {
            errorMessage.tipoTransportadora = "Tipo de frete não selecionada"
            numErrors++
        }
        if (!selectCondPagamento.idErpCondPag) {
            errorMessage.condPagamento = "Condição de Pagamento não selecionada"
            numErrors++
        }
        if (!selectTabelaPreco.codTabPreco) {
            errorMessage.tabPreco = "Tabela de preço não selecionada"
            numErrors++
        }
        errorMessage.carrinho = []
        if (dataCarrinho.length < 1) {
            errorMessage.carrinho[0] = MENSAGENS_DE_ERRO.carrinho;
            numErrors++
        }
        dataCarrinho.map((obj, index) => {
            errorMessage.carrinho[index] = ""
            if (typeof obj.quantidade === "undefined" || obj.quantidade <= 0 || isNaN(obj.quantidade)) {
                errorMessage.carrinho[index] += MENSAGENS_DE_ERRO.qtd + " ;";
                numErrors++;
            }
            // if (!obj.quantidade || obj.quantidade > obj.qtdMax) {
            //     errorMessage.carrinho[index] += "Quantidade indisponível";
            //     numErrors++;
            // }
            if (obj.qtpcs && obj.quantidade % obj.qtpcs !== 0) {
                errorMessage.carrinho[index] += "Quantidade invalida ;";
                numErrors++;
            }
            if (typeof obj.preco === "undefined" || obj.preco <= 0) {
                errorMessage.carrinho[index] += MENSAGENS_DE_ERRO.preco + " ;";
                numErrors++;
            }
            if (typeof obj.desconto === "undefined" || obj.desconto >= (obj.quantidade * obj.preco)) {
                errorMessage.carrinho[index]  += "Desconto inválido ;"
                numErrors++
            }
            if (obj.descontoPc > 9) {
                errorMessage.carrinho[index] += "Não podem ser concedidos descontos acima de  9%"
                numErrors++
            }
            if(errorMessage.carrinho[index] ){
                errorMessage.carrinho[index] = `(Item ${index+1}) Produto ${obj.codProduto}: ${errorMessage.carrinho[index]}`  
            }
        })

        setError(errorMessage);
        return numErrors === 0;
    }

    const finalizaOrcamento = async () => {
        setLoadingSendPed(true);
        const newDate = new Date();
        const date = `${newDate.getFullYear()}${("0" + (newDate.getMonth() + 1)).slice(-2)}${("0" + newDate.getDate()).slice(-2)}`;
        
        if (validar()) {
            const arrayTransformado = dataCarrinho.map(obj => ({
                filial: obj.filial,
                codProduto: obj.codProduto,
                nmProduto: obj.nmProduto,
                um: obj.um,
                codColecao: obj.codColecao,
                quantidade: parseFloat(obj.quantidade),
                preco: obj.preco,
                desconto: obj.desconto,
                descontoPc: obj.descontoPc,
                pedido: obj.pedido || "",
                ipi: obj.ipi || 0,
                pedCliente: props.dataorc.pedCliente || "",
                total: obj.preco * parseFloat(obj.quantidade)
            }));
            const form = {
                "filial": select.idFilial,
                "codPedido": props.dataorc.codPedido ? props.dataorc.codPedido : "",
                "revisao": props.dataorc.revisao ? `${parseInt(props.dataorc.revisao) + 1}` : '1',
                "tipo": "1",
                "codCliente": selectCli.codCliente,
                "codLoja": selectCli.codLoja,
                "codTransportadora": selectTransportadora.idErpTransp,
                "codRedespacho": selectRedespacho.idErpTransp || "",
                "tipoCliente": "R",
                "condPagamento": selectCondPagamento.idErpCondPag,
                "tabela": selectTabelaPreco.codTabPreco,
                "vendedor": selectCli.codVendedor,
                "comissao": selectUser.comissao.toString(),
                "emissao": date,
                "tipoFrete": selectTipoTransportadora.value,
                "observacao": props.dataorc.observacao,
                "ultRevisao": "S",
                "email": props.dataorc.email,
                "ativo": "0",
                "item": arrayTransformado
            }
            const formAnterior = {
                "filial": select.idFilial,
                "codPedido": props.dataorc.codPedido,
                "revisao": props.dataorc.revisao,
                "tipo": "1",
                "codCliente": selectCli.codCliente,
                "codLoja": selectCli.codLoja,
                "codTransportadora": selectTransportadora.idErpTransp,
                "codRedespacho": selectRedespacho.idErpTransp || "",
                "tipoCliente": "R",
                "condPagamento": selectCondPagamento.idErpCondPag,
                "tabela": selectTabelaPreco.codTabPreco,
                "vendedor": selectCli.codVendedor,
                "comissao": selectUser.comissao.toString(),
                "emissao": date,
                "tipoFrete": selectTipoTransportadora.value,
                "observacao": props.dataorc.observacao,
                "ultRevisao": "N",
                "email": props.dataorc.email,
                "ativo": "3",
            }
            try {
                setPedidoFinal(form)
                if (props.dataorc.codPedido) {
                    await putPedido(formAnterior,null);
                }
                const resp = await postPedido(form);
                if (resp.status === 200) {
                    setNumCotacao(resp.data);
                    setShow(true);
                }
            } catch (error) {
                console.error("Erro ao finalizar pedido:", error);
            } finally {
                setLoadingSendPed(false);
            }
        } else {
            setLoadingSendPed(false);
        }
    };

    const fecharModal = () => {
        setShow(false);
        dispatch(deleteCarrinho('', true))
        navigate('/orcamentos')
    }

    const TotalCarrinho = () => {

        let result = 0
        dataCarrinho.map((item) => {
            // result = result + ((item.preco * item.quantidade) - item.desconto)
            result = result + (((item.preco * item.quantidade) - item.desconto)*(item.ipi/100 + 1))

            return result
        })
        setTotalCarrrinho(result)
        // if (result % 1 === 0){
        //      result = `${result},00`
        // }

        return (
            <div className="">
                <span className="font-bold">Total: R$ {convertDecimal(result.toFixed(2))}</span>
            </div>)
    }

    const enviaEmail = async () => {
        setLoadingEmail(true);
        // let dataFormatada = converterData(pedidoFinal.emissao);
        const formatarData = (data) => {
            var ano = data.substring(0, 4);
            var mes = data.substring(4, 6);
            var dia = data.substring(6, 8);

            return dia + '/' + mes + '/' + ano.substring(2, 4);
        };
        
        const formToPDF = {
            cliente: selectCli.nmCliente,
            cotacao: numCotacao.codPedido.substring(3,numCotacao.codPedido.length),
            revisao: pedidoFinal.revisao,
            emissao: formatarData(pedidoFinal.emissao),
            condPagamento: selectCondPagamento.nmCondPag,
            transportadora: selectTransportadora.nmTransp,
            redespacho: selectRedespacho.nmTransp || ' ',
            endereco: selectCli.endereco,
            itens: pedidoFinal.item
        }

        const gerarHTML = (pedidoFinal, dataFormatada) => {
            return `
            <html>
                <head>
                    <style>
                        table {
                            font-family: arial, sans-serif;
                            border-collapse: collapse;
                            width: 100%;
                        }

                        td, th {
                            border: 1px solid #dddddd;
                            text-align: left;
                            padding: 8px;
                        }

                        tr:nth-child(even) {
                            background-color: #dddddd;
                        }
                    </style>
                </head>
                <body>
                    <p>Olá ${selectCli.nmCliente},</p>
                    <p>Abaixo cotação enviado pelo representante:</p><br/>
                    <p>Cotação: ${numCotacao.codPedido.substring(3, numCotacao.codPedido.length)} || Revisão: ${pedidoFinal.revisao}</p>
                    <p>Emiss&atilde;o: ${dataFormatada}</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Código</th>
                                <th scope="col">Produto</th>
                                <th scope="col">Local</th>
                                <th scope="col">Qtd.</th>
                                <th scope="col">Valor</th>
                                <th scope="col">Desc.</th>
                                <th scope="col">Ipi</th>
                                <th scope="col">Total</th>
                                <th scope="col">Pedido de compra</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${pedidoFinal.item.map((item) => {
                                return (
                                    `<tr>
                                        <td>${item.codProduto}</td>
                                        <td>${item.nmProduto}</td>
                                        <td>${item.codColecao}</td>
                                        <td>${parseFloat(item.quantidade).toFixed(2)}</td>
                                        <td>${convertDecimal(item.preco.toFixed(2))}</td>
                                        <td>${convertDecimal(item.desconto.toFixed(2))}</td>
                                        <td>${item.ipi}</td>
                                        <td>${convertDecimal((((parseFloat(item.quantidade) * item.preco ) - item.desconto) * ((item.ipi / 100) + 1)).toFixed(2))}</td>
                                        <td>${item.pedido}</td>
                                    </tr>`
                                );
                            }).join('')}
                        </tbody>
                    </table>
                    <span><b>Total: R$ ${convertDecimal(totalCarrrinho.toFixed(2))}</b></span><br/>
                    <p>Atenciosamente, ${empresa}.</p><br/>
                </body>
            </html>`;
        };
    
        // const dataFormatada = formatarData(pedidoFinal.emissao);
        const htmlBody = gerarHTML(pedidoFinal, formatarData(pedidoFinal.emissao));
    
        try {
            await api.post('/email/send_email', {
                from_email: 'Pedidos Promex Mais <wf.pedidos@promexmais.com.br>',
                to_email: toEmail,
                subject: `Cotação - ${numCotacao.codPedido.substring(3, numCotacao.codPedido.length)}`,
                html_body: htmlBody,
                detPedido: formToPDF
            }).then((response) => response.status === 200 ? putPedido({...pedidoFinal, email:toEmail},null) : null);

            setLoadingEmail(false);
            fecharModal();
            setErroMassage({ ...erroMassage, email: "" });
        } catch (error) {
            setLoadingEmail(false);
            setErroMassage({ ...erroMassage, email: "Falha no envio do e-mail. Verifique sua conexão e tente novamente." });
        }finally {
            setLoadingEmail(false);
            fecharModal();
            setErroMassage({ ...erroMassage, email: "" });
        }
    };
    
    const ItemProdCarrinho = (props) => {
        let dataOrc = props.dataOrc

        const [itemProd, setItemProd] = useState({
            "filial": select.idFilial,
            "codCliente": dataOrc.codCliente,
            "codVendedor": dataOrc.codVendedor,
            "codProduto": props.item.codProduto,
            "nmProduto": props.item.nmProduto,
            "um": "UN",
            "codColecao": props.item.codColecao,
            "quantidade": props.item.quantidade || 0,
            "preco": props.item.preco || 0,
            "desconto": props.item.desconto || 0,
            "descontoPc": props.item.descontoPc || 0,
            "qtpcs": props.item.qtpcs || 1,
            "pedido": props.item.pedido || "",
            "ipi": props.item.ipi || 0,
            "ativo": 0,
            "id": props.index,
            "qtdMax": props.item.qtdMax || 0,
        });
        const [alertDelete, setAlertDelete] = useState(false);
        const [myTimeout, setMyTimeout] = useState(null);


        const deleteItemProd = () => {
            dispatch(deleteCarrinho(itemProd.id, false))
            setRefresh(!refresh)
        }
        
        // useEffect(() => {
        //     dispatch(putCarrinho({ ...itemProd, id: props.index }))
        // }, [itemProd]);

        const onChange = (value, type) => {
            
            switch (type) {
                case 'quantidade':
                    setItemProd(currentValue => {return { ...currentValue, quantidade: value, desconto: (currentValue.descontoPc * (value * currentValue.preco))/100 }})
                    dispatch(putCarrinho({ ...itemProd, quantidade: value, desconto: (itemProd.descontoPc * (value * itemProd.preco))/100 , id: props.index }))
                    break;
                case 'preco':
                    setItemProd(currentValue => {return { ...currentValue, preco: value, desconto: (currentValue.descontoPc * (currentValue.quantidade * value))/100 }})
                    dispatch(putCarrinho({ ...itemProd, preco: value,  desconto: (itemProd.descontoPc * (itemProd.quantidade * value))/100, id: props.index }))
                    break;
                case 'descontoPc':
                    setItemProd(currentValue => {return {...currentValue, descontoPc: value, desconto: (value * (currentValue.quantidade * currentValue.preco))/100}})
                    dispatch(putCarrinho({ ...itemProd, descontoPc: value, desconto: (value * (itemProd.quantidade * itemProd.preco))/100, id: props.index }))
                    break;
                case 'pedido':
                    setItemProd(currentValue => {return { ...currentValue, pedido: value }})
                    dispatch(putCarrinho({ ...itemProd, pedido: value, id: props.index }))
                    break;
            }
            clearTimeout(myTimeout)
            const newTimer = setTimeout(() => {
                setRefresh(!refresh)
            }, 1500)
            setMyTimeout(newTimer)
        }

        

        return (
            <div className={`flex flex-col border-2 p-2 my-1 rounded-md w-full ${props.index%2 ? 'bg-gray-200' :null}`}>
                <div className="flex flex-col justify-center w-full">
                    <p className="text-sm font-bold my-0">{itemProd.nmProduto}</p>
                    <p className="my-0 text-xs text-gray-500">{itemProd.codProduto }</p>
                    {myTimeout ? <LoadingIcon /> : null}
                </div>
                <div className="flex md:flex-row flex-col justify-center w-full md:space-x-2">
                    <div className="flex flex-row md:w-2/3 space-x-2">
                        <div className="w-2/4 p-0">
                            <FloatingLabel controlId="floatingInput" label={props.item.qtpcs !== 1 ? `Qtd. (x${props.item.qtpcs})` : "Qtd."} className="my-2">
                                <NumericFormat
                                    value={itemProd.quantidade}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    className="bg-slate-300"
                                    allowNegative={false}
                                    size="sm"
                                    customInput={Form.Control}
                                    onValueChange={(e) => onChange(e.floatValue, 'quantidade')}
                                />
                            </FloatingLabel>
                        </div>
                        <div className="w-2/4 p-0">
                            <FloatingLabel controlId="floatingInput" label={props.item.ipi === 0 ? "Preço": `Preço (ipi.${props.item.ipi})`} className="my-2">
                                <NumericFormat
                                        value={itemProd.preco ? itemProd.preco.toFixed(2) : 0}
                                        // thousandSeparator="."
                                        decimalSeparator=","
                                        prefix="R$ "
                                        decimalScale={2}
                                        size="sm"
                                        allowNegative={false}
                                        customInput={Form.Control}
                                        onValueChange={(e) => onChange(e.floatValue, 'preco')}

                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <div className="w-2/4 p-0">
                        <FloatingLabel controlId="floatingInput" label={itemProd.descontoPc ? 'Desc.% (R$ '+convertDecimal(itemProd.desconto.toFixed(2))+')' : 'Desc.%'} className="my-2">
                            <NumericFormat
                                value={itemProd.descontoPc ? itemProd.descontoPc : 0}
                                // thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={5}
                                // fixedDecimalScale={true}
                                // onlyRead
                                // disabled 
                                size="sm"
                                allowNegative={false}
                                customInput={Form.Control}
                                onValueChange={(e) => onChange(e.floatValue, 'descontoPc')}
                            />
                        </FloatingLabel>
                    </div>
                </div>
                <div className="flex justify-between ml-1">
                    <a onClick={() => setAlertDelete(!alertDelete)} className="text-blue-500">Excluir</a> 
                    <p className="font-bold">{itemProd.quantidade ? 'R$ '+convertDecimal((((itemProd.quantidade * itemProd.preco) - itemProd.desconto)*((props.item.ipi/100)+1)).toFixed(2)) : 'R$ 0,00'}</p>
                </div>
                
                <Modal show={alertDelete} onHide={() => setAlertDelete(!alertDelete)} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="bg-green-500">
                        <Modal.Title>Deseja remover produto {itemProd.codProduto} ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => deleteItemProd()}>
                            Confirmar
                        </Button>
                        <Button variant="secondary" onClick={() => setAlertDelete(false)}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    const ItensCarrinho = (props) => {
        let carrinho = props.carrinho
        let dataOrc = props.dataOrc
        return (
            <div className="w-full">
                <h3 >Produtos</h3>
                <div className="overflow-y-scroll w-full rounded-lg border p-1 mb-2">
                    {carrinho.map((item, index) => {
                        return (
                            <ItemProdCarrinho item={item} key={index} index={index} dataOrc={dataOrc} />
                        )
                    })}
                </div>
            </div>

        )
    }

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className="p-3">
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4 className="m-0">Finalizar Cotação</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                {errorForm ? <p className="text-red-600">{errorForm}</p> : null}
                <div className="flex flex-col w-full rounded-lg border p-1 mb-2">
                    {dataUser.length > 1 ? 
                        <div className="flex flex-col w-full my-1">
                            <FloatingLabel controlId="floatingInput" label="Vendedor" className="my-1 w-full sm">
                                <Form.Control defaultValue={selectUser.nmVendedor} aria-label="Disabled input example" readOnly />
                            </FloatingLabel>
                            {/* {error.cliente && <p className="text-xs text-red-500">{error.cliente}</p>} */}
                        </div>
                        :null
                    }
                    <div className="flex flex-col w-full">
                        <FloatingLabel controlId="floatingInput" label="Cliente" className="my-1 w-full sm">
                            <Form.Control defaultValue={selectCli.nmCliente} aria-label="Disabled input example" readOnly />
                        </FloatingLabel>
                        {error.cliente && <p className="text-xs text-red-500">{error.cliente}</p>}
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-4">
                        <div className="w-full flex-col">
                            <FloatingLabel controlId="floatingInput" label="Condição de Pagamento" className="my-2 w-full">
                                <Form.Control defaultValue={selectCondPagamento.nmCondPag} aria-label="Disabled input example" readOnly />
                            </FloatingLabel>
                            {error.condPagamento && <p className="text-xs text-red-500">{error.condPagamento}</p>}
                        </div>
                        <div className="w-full flex-col">
                            <FloatingLabel controlId="floatingInput" label="Tabela de preço" className="my-2 w-full">
                                <Form.Control defaultValue={selectTabelaPreco.nmTabPreco} aria-label="Disabled input example" readOnly />
                            </FloatingLabel>
                            {error.tabPreco && <p className="text-xs text-red-500">{error.tabPreco}</p>}
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-4">
                        <div className="w-full flex-col">
                            <FloatingLabel controlId="floatingInput" label="Tipo Frete" className="my-2 w-full">
                                <Form.Control defaultValue={selectTipoTransportadora.label} aria-label="Disabled input example" readOnly />
                            </FloatingLabel>
                            {error.tipoTransportadora && <p className="text-xs text-red-500">{error.tipoTransportadora}</p>}
                        </div>
                        <div className="w-full flex-col">
                            <FloatingLabel controlId="floatingInput" label="Transportadora" className="my-2">
                                <Form.Control defaultValue={selectTransportadora.nmTransp} aria-label="Disabled input example" readOnly />
                            </FloatingLabel>
                            {error.transportadora && <p className="text-xs text-red-500">{error.transportadora}</p>}
                        </div>
                    </div>
                    <div className="w-full flex-col">
                        <FloatingLabel controlId="floatingInput" label="Redespacho" className="my-2">
                            <Form.Control defaultValue={selectRedespacho.nmTransp} aria-label="Disabled input example" readOnly />
                        </FloatingLabel>
                    </div>
                    <FloatingLabel controlId="floatingInput" label="Pedido cliente" className="my-2">
                        <Form.Control defaultValue={props.dataorc.pedCliente} aria-label="Disabled input example" readOnly />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingInput" label="Observação" className="my-2">
                        <Form.Control defaultValue={props.dataorc.observacao} aria-label="Disabled input example" readOnly />
                    </FloatingLabel>
                    {/* <TotalCarrinho /> */}
                </div>
                <ItensCarrinho dataOrc={props.dataorc} carrinho={dataCarrinho} />
                {error.carrinho ? error.carrinho.length > 0 ? error.carrinho.map((i) => <><p className="text-xs text-red-500">{i}<br /> </p></>): error.carrinho : null}
                {/* {error.carrinho && <p className="text-xs text-red-500">{error.carrinho}</p>} */}
            </Modal.Body>
            <Modal.Footer className="container" style={{display:"unset"}}>
                <div className="row justify-content-start align-items-center">
                    <div className="col-md-4 p-1">
                        <TotalCarrinho />
                    </div>

                    <div className="col-md p-1 d-flex align-items-center justify-content-center">
                        <Button variant="warning" size="sm" className='w-100 btn d-flex align-items-center justify-content-center' onClick={() => setShowAlert(true)}>
                            <IoRefresh className='mr-1' />
                            Limpar carrinho
                        </Button>
                    </div>
                    <div className="col-md p-1 d-flex align-items-center justify-content-center">
                        <Button disabled={loadingSendPed} variant="success" size="sm" className='w-100 btn d-flex align-items-center justify-content-center' onClick={() => finalizaOrcamento()}>
                            <IoCartOutline className='mr-1' />
                            Finalizar
                        </Button>
                    </div>
                    <div className="col-md p-1 d-flex align-items-center justify-content-center">
                        <Button variant="danger" size="sm" className='w-100 btn d-flex align-items-center justify-content-center' onClick={() => props.onHide()}>
                            <IoClose className='mr-1' />

                            Fechar
                        </Button>
                    </div>
                    {/* <Button onClick={() => props.onHide()}>Fechar</Button> */}
                    {/* </div> */}
                </div>
            </Modal.Footer>
            <div className="modal show">
                <Modal show={showAlert} onHide={() => setShowAlert(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="bg-yellow-400">
                        <Modal.Title className="fs-4">Deseja remover todos os itens do carrinho?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button  variant="success" size="sm" onClick={() => { dispatch(deleteCarrinho('', true)); props.onHide(); setShowAlert(false) }}>
                            Confirmar
                        </Button>
                        <Button  variant="danger" size="sm" onClick={() => setShowAlert(false)}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="modal show" style={{ display: 'block', position: 'initial' }}>
                <Modal show={show} onHide={() => fecharModal(false)} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                    <Modal.Header closeButton className="bg-green-500">
                        <Modal.Title>Cotação gerada com sucesso! ({numCotacao.codPedido ? "Cotação "+numCotacao.codPedido.substring(3,numCotacao.codPedido.length)+" Revisão "+numCotacao.revisao: ""})</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Modal.Title className="fs-6">Deseja enviar cotação para cliente?</Modal.Title>
                        <FloatingLabel controlId="floatingInput" label="Email (Separe os e-mails utilizando ; )" className="my-2">
                            <Form.Control value={toEmail} as="input"  maxLength={140} onChange={e => setToEmail(e.target.value)} />
                        </FloatingLabel>
                        {erroMassage.email && <p className="text-xs text-red-500">{erroMassage.email}</p>}
                        {/* <FloatingLabel controlId="floatingInput" label="Observação" className="my-2">
                            <Form.Control as="textarea" rows={3} onChange={e => setObservacao(e.target.value)}/>
                        </FloatingLabel> */}
                    </Modal.Body>
                    <Modal.Footer>
                        {loadingEmail && <LoadingIcon />}
                        <Button disabled={loadingEmail} variant="success" size="sm" onClick={() => enviaEmail()}>
                            {loadingEmail ? 'Enviando...' : 'Enviar'}
                        </Button>
                        <Button variant="danger" size="sm" onClick={() => fecharModal()}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Modal>
    );
}

export default ViewCarrinho