import React, { useEffect, useState } from "react";
import { Accordion, Button, Modal, Form, Row, Col, InputGroup, FloatingLabel } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { postCarrinho } from '../../../store/Carrinho';
import { getCarrinho, putCarrinho, deleteCarrinho } from '../../../store/Carrinho';
import { checkToken } from '../../../store/Usuarios'
import api from "../../../services/api";
import { NumericFormat } from 'react-number-format';
import { convertDecimal } from '../../../function/maskInputMoney';

const AddCarrinho = (props) => {
    const dispatch = useDispatch()
    const { select } = useSelector(state => state.filialReduce)
    const { selectCli } = useSelector(state => state.clienteReduce)
    const { selectTabelaPreco } = useSelector(state => state.precoReduce)

    const [error, setError] = useState({ qtd: '', preco: '', desconto: '' });
    const [formProd, setFormProd] = useState({
        filial: select.idFilial,
        codCliente: selectCli.codCliente,
        codVendedor: selectCli.codVendedor,
        codProduto: props.item.codCor === 'UNI' ? props.item.idErpProduto : `${props.item.idErpProduto}C${props.item.codCor}`,
        nmProduto: `${props.item.nmProduto}/${props.item.nmCor}`,
        um: props.item.um,
        codColecao: "95",
        quantidade: 0,
        preco: 0,
        desconto: 0,
        descontoPc: 0,
        ipi: props.item.ipi,
        qtpcs: props.item.qtpcs,
        pedido: "",
        ativo: '1',
        qtdMax: props.item.qtd

    });

    useEffect(() => {
        setError({ qtd: '', preco: '', desconto: '' })
        setFormProd(prevFormProd => ({
            ...prevFormProd,
            filial: select.idFilial,
            codVendedor: selectCli.codVendedor,
            codCliente: selectCli.codCliente,
            codProduto: props.item.codCor === 'UNI' ? props.item.idErpProduto : `${props.item.idErpProduto}C${props.item.codCor}`,
            nmProduto: `${props.item.nmProduto}/${props.item.nmCor}`,
            um: props.item.um,
            codColecao: "95",
            quantidade: 0,
            qtpcs: props.item.qtpcs,
            ipi: props.item.ipi,
            qtdMax: props.item.qtd
        }));
        getPreco()

    }, [props.item, selectCli, selectTabelaPreco]);

    const getPreco = () => {
        const params = new URLSearchParams([['codProduto', `${props.item.idErpProduto}C${props.item.codCor}`], ['codTab', selectTabelaPreco.codTabPreco]]);
        api
            .get(`/precos/ProdPreco/`, { headers: { Authorization: `Bearer ${checkToken()}` }, params })
            .then((response) => response.data[0].prodPreco ?  setFormProd(prevFormProd => ({ ...prevFormProd, ['preco']: response.data[0].prodPreco })):"")
            // .then((response) => console.log("response",response.data ))
            
            .catch((err) => {
                console.error("Erro: ", err);
            });
    }

    const onChangeForm = (e) => {
        const { floatValue } = e;
        if (floatValue > props.item.qtd) {
            setError(prevError => ({ ...prevError, qtd: "Quantidade indisponível" }))
        }else{
            setError(prevError => ({ ...prevError, qtd: '' }))
        }
        setFormProd(prevFormProd => ({
            ...prevFormProd,
            quantidade: floatValue,
            desconto: (prevFormProd.descontoPc * (floatValue * prevFormProd.preco)) / 100,
            codProduto: props.item.codCor === 'UNI' ? props.item.idErpProduto : `${props.item.idErpProduto}C${props.item.codCor}`,
            nmProduto: `${props.item.nmProduto}/${props.item.nmCor}`,
            um: props.item.um,
            ipi: props.item.ipi,
            qtpcs: props.item.qtpcs
        }));
    }

    const postForm = () => {
        const validacao = (form) => {
            let numErrors = 0;
            let errorMessage = {};

            if (!form.quantidade || form.quantidade > props.item.qtd) {
                errorMessage.qtd = "Quantidade indisponível";
                numErrors++;
            }
            if (form.quantidade <= 0 || Number.isNaN(form.quantidade)) {
                errorMessage.qtd = "Informar quantidade";
                numErrors++;
            }
            if (form.qtpcs && form.quantidade % form.qtpcs !== 0) {
                errorMessage.qtd = "Quantidade inválida";
                numErrors++;
            }
            if (!form.preco || form.preco <= 0) {
                errorMessage.preco = "Informar preço";
                numErrors++;
            }
            if (form.desconto >= (form.quantidade * form.preco)) {
                errorMessage.desconto = "Desconto inválido";
                numErrors++;
            }
            if (form.descontoPc > 9) {
                errorMessage.desconto = "Não podem ser concedidos descontos acima de 9%";
                numErrors++;
            }
            setError(errorMessage);
            return numErrors === 0;
        }
        
        if (validacao(formProd)) {
            dispatch(postCarrinho(formProd));
            setFormProd(prevFormProd => ({
                ...prevFormProd,
                filial: select.idFilial,
                codCliente: selectCli.codCliente,
                codVendedor: selectCli.codVendedor,
                codProduto: "",
                nmProduto: "",
                um: "",
                codColecao: "",
                quantidade: 0,
                preco: 0.00,
                pedido: "",
                desconto: 0.0,
                descontoPc: 0.0,
                qtpcs: 1,
                ipi: 0,
                ativo: '1'
            }));
            props.onHide();
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>{props.item.nmProduto}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form name={props.prod_id} >
                    <div className="flex flex-col">
                        <div className="flex md:flex-row md:space-x-3 w-full flex-col">
                            <div className="flex flex-col p-0">
                                <FloatingLabel controlId="floatingInput" label={props.item.qtpcs !== 1 ? `Qtd. (x${props.item.qtpcs})` : "Qtd."} className="my-2">
                                    <NumericFormat
                                        value={formProd.quantidade}
                                        decimalSeparator=","
                                        decimalScale={2}
                                        className="bg-slate-300"
                                        size="sm"
                                        allowNegative={false}
                                        customInput={Form.Control}
                                        onValueChange={onChangeForm}
                                    />
                                </FloatingLabel>
                                {error.qtd && <p className="text-xs text-red-500">{error.qtd}</p>}
                            </div>
                            <div className="flex flex-col p-0">
                                <FloatingLabel controlId="floatingInput" label={props.item.ipi === 0 ? "Preço": `Preço (ipi.${props.item.ipi})`} className="my-2">
                                    <NumericFormat
                                            value={formProd.preco ? formProd.preco.toFixed(2) : 0}
                                            decimalSeparator=","
                                            prefix="R$ "
                                            decimalScale={2}
                                            size="sm"
                                            allowNegative={false}
                                            customInput={Form.Control}
                                            onValueChange={(e) => setFormProd(prevFormProd => ({ ...prevFormProd, preco: e.floatValue || 0 }))}

                                    />
                                </FloatingLabel> 
                                {error.preco && <p className="text-xs text-red-500">{error.preco}</p>}     
                            </div>
                            <div className="flex flex-col p-0">
                                <FloatingLabel controlId="floatingInput" label={formProd.descontoPc ? 'Desc.% (R$ '+convertDecimal(formProd.desconto.toFixed(2))+')' : 'Desc.%'} className="my-2">
                                    <NumericFormat
                                        value={formProd.descontoPc ? formProd.descontoPc : 0}
                                        decimalSeparator=","
                                        decimalScale={5}
                                        size="sm"
                                        allowNegative={false}
                                        customInput={Form.Control}
                                        onValueChange={(e) => setFormProd(prevFormProd => ({ ...prevFormProd, descontoPc: e.floatValue || 0, desconto: ( e.floatValue * (prevFormProd.quantidade * prevFormProd.preco))/100}))}
                                    />
                                </FloatingLabel>
                                {error.desconto && <p className="text-xs text-red-500">{error.desconto}</p>}
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex flex-row justify-between w-full">
                    <div className="w-full">
                        <p className="font-bold">{formProd.quantidade ? 'R$ '+convertDecimal((((formProd.quantidade * formProd.preco) - formProd.desconto)*((props.item.ipi/100)+1)).toFixed(2)) : 'R$ 0,00'}</p>
                    </div>
                    <div className="flex flex-row justify-end w-full">
                        <Button className="mx-1" variant="primary" size="sm" onClick={() => postForm()}>Adicionar no carrinho</Button>
                        <Button className="mx-1" variant="danger" size="sm" onClick={() => props.onHide()}>Fechar</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
export default AddCarrinho;
